header {
  background-color: #e7f068;
  padding: 20px;
  text-align: left;
  border-bottom: 1px solid #090707;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-list {
  margin-left: 20%;
  margin-right: 20%;
  padding: 20px;
}

.service-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.service-name {
  font-weight: bold;
}

.service-status {
  text-transform: uppercase;
}

/* Example status colors */
.status-up {
  color: green;
}

.status-down {
  color: red;
}

.product-card {
  border-radius: 8px;
  border: 1px solid #e7f068;
  background: var(--product-card-background-color, #e7f068);
  display: flex;
  padding: 16px;
  gap: 8px;
  flex-direction: row;
  align-self: stretch;
}

.components-section {
  margin-bottom: 10px;
}

.column-grid-container {
  gap: 48px;
  border-radius: 8px;
  font-size: 15px;
  line-height: 24px;
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.component-list-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
}

.header-image {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.row {
  display: flex;
  justify-content: space-between;
}

.container {
  padding: 10px;
  border: 0.8px solid #b0b0b0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service-name {
  font-weight: bold;
  font-size: 1rem;
}

.font-largest {
  font-weight: 500;
  font-size: 28px;
  line-height: 37.8px;
  font-size: 1.75rem;
  line-height: 2.3625rem;
  margin-bottom: 10px;
}

.description-style {
  margin-bottom: 50px;
  color: #b0b0b0;
}

.links {
  margin-top: 50px;
}

.collapsible-row {
  cursor: pointer;
  width: 100%;
  font-weight: bold;
}

.row-header {
  display: flex;
  align-items: center;
}
.links-container {
  display: flex;
  flex-direction: column;
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.product-card-icon {
  margin-right: 8px;
}

a:not(:first-child) {
  margin-top: 8px;
}
a {
  margin-left: 32px;
}

.collapsible {
  color: rgb(0, 0, 0);
  cursor: pointer;
  padding: 20px;
  width: 100%;
  border: dark;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active,
.container:hover {
  background-color: #e7f068;
}

.collapsible:after {
  color: rgb(16, 3, 3);
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  float: right;
}

.content {
  padding: 0 20px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  background-color: #000000;
}

a {
  margin-top: 15px;
}

.card {
  border: 1px solid #666262;
  border-radius: 8px;
  width: 300px;
  margin: auto;
  background-color: #e7f068;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  margin-top: 16%;
}

.card-title {
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
}

.google-auth-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
}

.google-auth-btn:hover {
  background-color: #357ae8;
}

.logout-button {
  background-color: #000000;
  border: none;
  color: white;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

h2 {
  text-align: center; /* Centers the title */
}

.item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
